// PreviewModal.js
import React from 'react';
import './PreviewModal.css'; // Use unique CSS classes for the preview modal

const PreviewModal = ({ title, previewContent, previewError, onClose }) => {
    return (
        <div className="preview-modal">
            <div className="preview-modal-content">
                <button className="close-button" onClick={onClose}>×</button>
                <h3>Preview: {title}</h3>
                {previewError ? (
                    <p style={{ color: "red" }}>{previewError}</p>
                ) : previewContent ? (
                    <div className="preview-container">
                        {previewContent.startsWith("data:image") ? (
                            <img src={previewContent} alt="Preview" style={{ maxWidth: "100%" }} />
                        ) : (
                            <pre style={{ whiteSpace: "pre-wrap" }}>{previewContent}</pre>
                        )}
                    </div>
                ) : (
                    <p>Loading preview...</p>
                )}
            </div>
        </div>
    );
};

export default PreviewModal;
