// MetadataModal.js
import React from 'react';
import './MetadataModal.css';

const MetadataModal = ({ metadataText, onClose, onCopy }) => {
    return (
        <div className="metadata-modal">
            <div className="metadata-modal-content">
                <button className="close-button" onClick={onClose}>×</button>
                <h3>Generated Metadata</h3>
                <pre className="metadata-text">{metadataText}</pre>
                <button onClick={onCopy}>Copy to Clipboard</button>
            </div>
        </div>
    );
};

export default MetadataModal;
